
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45usHD5Dk8gW1Vi7uoxM7hw0SitIX6DWvjOCMvDI_45sG8zfwMeta } from "/vercel/path0/pages/[locale]/about-us.vue?macro=true";
import { default as careersUtm6zF1DsFm_45Ge2k3TSsymQJ2FSoL6wkWfWzTX_AvUQMeta } from "/vercel/path0/pages/[locale]/careers.vue?macro=true";
import { default as disclaimerb4kCErvCMfoh_45h49jwwRV0ANMfNwz9IeXC7fKoxj7Y8Meta } from "/vercel/path0/pages/[locale]/disclaimer.vue?macro=true";
import { default as _91fid_93XpnIUWJTtQScC_45Hb1WQi10f_45n4R8ysIW6v7CYIFd_45MgMeta } from "/vercel/path0/pages/[locale]/events/[fid].vue?macro=true";
import { default as archivePS9PAYSv1XywMqqC_48NhClcxwIS_45tsHbJKtVPWUGe0Meta } from "/vercel/path0/pages/[locale]/events/archive.vue?macro=true";
import { default as indexKF2hiuGcjkEy_45cFcJ06grL9Ckhj7CsQ_45f4NWo_KyDewMeta } from "/vercel/path0/pages/[locale]/index.vue?macro=true";
import { default as _91fid_93yuwSdi7Xx4QVKX1W6_451geffBlcpmh4ramra4nME_2kEMeta } from "/vercel/path0/pages/[locale]/lawyers/[fid].vue?macro=true";
import { default as GetLawyerPrintTemplaterApfCcvI3JNUlW5XVEx_45W1ey4fVU67FVPXFy0UXzFi4Meta } from "/vercel/path0/pages/[locale]/lawyers/GetLawyerPrintTemplate.ts?macro=true";
import { default as _91fid_93QKFia18GYPHguwRL6VWswV0HZU3Y8JycHVVtVw_jDWcMeta } from "/vercel/path0/pages/[locale]/legal-alerts/[fid].vue?macro=true";
import { default as archiveSOHu_45nmv5OsFUpcjpOCEA5n1b8ZU_45WmMXq_453skBS79UMeta } from "/vercel/path0/pages/[locale]/legal-alerts/archive.vue?macro=true";
import { default as indexhmFZrF0aUeV_45EBtQb5MfHcIG_1x_45wOkIghOXMF_YEJEMeta } from "/vercel/path0/pages/[locale]/legal-alerts/index.vue?macro=true";
import { default as _91fid_93WOAnxdZFPavjrlUjGgoDYniuEQPPA7jeFQyhsRJzV9EMeta } from "/vercel/path0/pages/[locale]/media-articles/[fid].vue?macro=true";
import { default as archivesBqZx8fJfmcCB7CT5j2pu2lLPz6TTeTHiRyVYLO27YsMeta } from "/vercel/path0/pages/[locale]/media-articles/archive.vue?macro=true";
import { default as mediamc7bmuwXYO_45svVaaPGzm_45dge4xABNV8DVq1ALvF7CMIMeta } from "/vercel/path0/pages/[locale]/media.vue?macro=true";
import { default as peopleYTYpB3LLeT6mdIeW_DY7xnflOY7rLuLsvup136VNzBwMeta } from "/vercel/path0/pages/[locale]/people.vue?macro=true";
import { default as searchqiXUI_Ku_ApIfChMGW_WCkyQ3uESt_45igXSAo27Svzi4Meta } from "/vercel/path0/pages/[locale]/search.vue?macro=true";
import { default as servicespLEMniiNrFeSEVIR1b5KdhC_eeXZasws7pIFA2HJdNEMeta } from "/vercel/path0/pages/[locale]/services.vue?macro=true";
import { default as apiControllervW9Qhf60gVmyYYvuRpfIEUDiYCYOT8LCpZSSNL0f_5wMeta } from "/vercel/path0/pages/a/apiController.ts?macro=true";
import { default as apiResponseHandlerTSiwA1rtKLsIYeoFICE_W_45mZ_zsJ4TfyRDpoq739p9oMeta } from "/vercel/path0/pages/a/apiResponseHandler.ts?macro=true";
import { default as bannersjU4KVWaytqCSst3ZLo3BJrKPzshIzXO4dYB8GTkB1hoMeta } from "/vercel/path0/pages/a/banners.vue?macro=true";
import { default as discoveryDNpVuR_n_45LFWW_UPmyZoN0Bm_45fI5FKEma67xPbyzhIQMeta } from "/vercel/path0/pages/a/discovery.vue?macro=true";
import { default as edit_45bannerNbWWy8RH2xsr6CiJ639l5CBKoEXWff3W6ryQTiNUdekMeta } from "/vercel/path0/pages/a/edit-banner.vue?macro=true";
import { default as edit_45events0JpiGkLNiS7Q_w2Yw3UEYLOZXvot9UBReRXs1LhXaUMeta } from "/vercel/path0/pages/a/edit-event.vue?macro=true";
import { default as edit_45lawyerLlmS55QedLU8t_45czobdScjzngRyLZ55LzsvhKrFKyVcMeta } from "/vercel/path0/pages/a/edit-lawyer.vue?macro=true";
import { default as edit_45legal_45alert6o2yeJf7RvhNIhXYhrNdlsZ_7odzbtheYou9TSApq5kMeta } from "/vercel/path0/pages/a/edit-legal-alert.vue?macro=true";
import { default as edit_45media_45article0G_45VZqChJUWpTo9yOM8Bl4SZD1KXxqBDFpI30tU_45S0MMeta } from "/vercel/path0/pages/a/edit-media-article.vue?macro=true";
import { default as edit_45section_45headerRZwT1aXts7yBBEm6F7k3G_45KgVMUDXQkpKqdCFGszFPQMeta } from "/vercel/path0/pages/a/edit-section-header.vue?macro=true";
import { default as eventskD03lx2fVm2Bpms_v5C_454m7H6h0gncqtHXBs8spaEh0Meta } from "/vercel/path0/pages/a/events.vue?macro=true";
import { default as forcedReloadergsqfDeUdqtFKjZrWeV_45zLVGujcam_t5lQYXvjpMG6g0Meta } from "/vercel/path0/pages/a/forcedReloader.ts?macro=true";
import { default as indexMuXcOFeSpoZqWWXTvt3PwNMPwDddIJWJ6wBdNATLt6QMeta } from "/vercel/path0/pages/a/index.vue?macro=true";
import { default as lawyers8NpZbOPXXgqkKFkU3MG2w7ybdHNtz_3ieZHlr0AHKlsMeta } from "/vercel/path0/pages/a/lawyers.vue?macro=true";
import { default as legal_45alertsElwcYcdJucBB_45tCfvzxMAX8gpM3PP_45wDjtXdsH7lgkwMeta } from "/vercel/path0/pages/a/legal-alerts.vue?macro=true";
import { default as media_45articleswYC6zWHFrHuWINxaeNikcISzcKkQmAuXkOwHGzxvs8AMeta } from "/vercel/path0/pages/a/media-articles.vue?macro=true";
import { default as modelReloaderW6pzEaNEpePvqShjOT_JIcCIAAdc4R6DtMyn9xdPnhAMeta } from "/vercel/path0/pages/a/modelReloader.ts?macro=true";
import { default as section_45headersDUL_45lIC_45lJ8s_NI6qCiBeqm0T_45xzCk7j1GRdpAqXxc4Meta } from "/vercel/path0/pages/a/section-headers.vue?macro=true";
import { default as loginyiubrZraL9EDD3J38Z_45ftLpCUym9OoYm1Hb9453rWnMMeta } from "/vercel/path0/pages/login.vue?macro=true";
export default [
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/vercel/path0/pages/[locale]/about-us.vue")
  },
  {
    name: "locale-careers",
    path: "/:locale()/careers",
    component: () => import("/vercel/path0/pages/[locale]/careers.vue")
  },
  {
    name: "locale-disclaimer",
    path: "/:locale()/disclaimer",
    component: () => import("/vercel/path0/pages/[locale]/disclaimer.vue")
  },
  {
    name: "locale-events-fid",
    path: "/:locale()/events/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/events/[fid].vue")
  },
  {
    name: "locale-events-archive",
    path: "/:locale()/events/archive",
    component: () => import("/vercel/path0/pages/[locale]/events/archive.vue")
  },
  {
    name: "locale",
    path: "/:locale()",
    component: () => import("/vercel/path0/pages/[locale]/index.vue")
  },
  {
    name: "locale-lawyers-fid",
    path: "/:locale()/lawyers/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/lawyers/[fid].vue")
  },
  {
    name: "locale-lawyers-GetLawyerPrintTemplate",
    path: "/:locale()/lawyers/GetLawyerPrintTemplate",
    component: () => import("/vercel/path0/pages/[locale]/lawyers/GetLawyerPrintTemplate.ts")
  },
  {
    name: "locale-legal-alerts-fid",
    path: "/:locale()/legal-alerts/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/legal-alerts/[fid].vue")
  },
  {
    name: "locale-legal-alerts-archive",
    path: "/:locale()/legal-alerts/archive",
    component: () => import("/vercel/path0/pages/[locale]/legal-alerts/archive.vue")
  },
  {
    name: "locale-legal-alerts",
    path: "/:locale()/legal-alerts",
    component: () => import("/vercel/path0/pages/[locale]/legal-alerts/index.vue")
  },
  {
    name: "locale-media-articles-fid",
    path: "/:locale()/media-articles/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/media-articles/[fid].vue")
  },
  {
    name: "locale-media-articles-archive",
    path: "/:locale()/media-articles/archive",
    component: () => import("/vercel/path0/pages/[locale]/media-articles/archive.vue")
  },
  {
    name: "locale-media",
    path: "/:locale()/media",
    component: () => import("/vercel/path0/pages/[locale]/media.vue")
  },
  {
    name: "locale-people",
    path: "/:locale()/people",
    component: () => import("/vercel/path0/pages/[locale]/people.vue")
  },
  {
    name: "locale-search",
    path: "/:locale()/search",
    component: () => import("/vercel/path0/pages/[locale]/search.vue")
  },
  {
    name: "locale-services",
    path: "/:locale()/services",
    component: () => import("/vercel/path0/pages/[locale]/services.vue")
  },
  {
    name: "a-apiController",
    path: "/a/apiController",
    component: () => import("/vercel/path0/pages/a/apiController.ts")
  },
  {
    name: "a-apiResponseHandler",
    path: "/a/apiResponseHandler",
    component: () => import("/vercel/path0/pages/a/apiResponseHandler.ts")
  },
  {
    name: "a-banners",
    path: "/a/banners",
    meta: bannersjU4KVWaytqCSst3ZLo3BJrKPzshIzXO4dYB8GTkB1hoMeta || {},
    component: () => import("/vercel/path0/pages/a/banners.vue")
  },
  {
    name: "a-discovery",
    path: "/a/discovery",
    meta: discoveryDNpVuR_n_45LFWW_UPmyZoN0Bm_45fI5FKEma67xPbyzhIQMeta || {},
    component: () => import("/vercel/path0/pages/a/discovery.vue")
  },
  {
    name: "a-edit-banner",
    path: "/a/edit-banner",
    meta: edit_45bannerNbWWy8RH2xsr6CiJ639l5CBKoEXWff3W6ryQTiNUdekMeta || {},
    component: () => import("/vercel/path0/pages/a/edit-banner.vue")
  },
  {
    name: "a-edit-event",
    path: "/a/edit-event",
    meta: edit_45events0JpiGkLNiS7Q_w2Yw3UEYLOZXvot9UBReRXs1LhXaUMeta || {},
    component: () => import("/vercel/path0/pages/a/edit-event.vue")
  },
  {
    name: "a-edit-lawyer",
    path: "/a/edit-lawyer",
    meta: edit_45lawyerLlmS55QedLU8t_45czobdScjzngRyLZ55LzsvhKrFKyVcMeta || {},
    component: () => import("/vercel/path0/pages/a/edit-lawyer.vue")
  },
  {
    name: "a-edit-legal-alert",
    path: "/a/edit-legal-alert",
    meta: edit_45legal_45alert6o2yeJf7RvhNIhXYhrNdlsZ_7odzbtheYou9TSApq5kMeta || {},
    component: () => import("/vercel/path0/pages/a/edit-legal-alert.vue")
  },
  {
    name: "a-edit-media-article",
    path: "/a/edit-media-article",
    meta: edit_45media_45article0G_45VZqChJUWpTo9yOM8Bl4SZD1KXxqBDFpI30tU_45S0MMeta || {},
    component: () => import("/vercel/path0/pages/a/edit-media-article.vue")
  },
  {
    name: "a-edit-section-header",
    path: "/a/edit-section-header",
    meta: edit_45section_45headerRZwT1aXts7yBBEm6F7k3G_45KgVMUDXQkpKqdCFGszFPQMeta || {},
    component: () => import("/vercel/path0/pages/a/edit-section-header.vue")
  },
  {
    name: "a-events",
    path: "/a/events",
    meta: eventskD03lx2fVm2Bpms_v5C_454m7H6h0gncqtHXBs8spaEh0Meta || {},
    component: () => import("/vercel/path0/pages/a/events.vue")
  },
  {
    name: "a-forcedReloader",
    path: "/a/forcedReloader",
    component: () => import("/vercel/path0/pages/a/forcedReloader.ts")
  },
  {
    name: "a",
    path: "/a",
    meta: indexMuXcOFeSpoZqWWXTvt3PwNMPwDddIJWJ6wBdNATLt6QMeta || {},
    component: () => import("/vercel/path0/pages/a/index.vue")
  },
  {
    name: "a-lawyers",
    path: "/a/lawyers",
    meta: lawyers8NpZbOPXXgqkKFkU3MG2w7ybdHNtz_3ieZHlr0AHKlsMeta || {},
    component: () => import("/vercel/path0/pages/a/lawyers.vue")
  },
  {
    name: "a-legal-alerts",
    path: "/a/legal-alerts",
    meta: legal_45alertsElwcYcdJucBB_45tCfvzxMAX8gpM3PP_45wDjtXdsH7lgkwMeta || {},
    component: () => import("/vercel/path0/pages/a/legal-alerts.vue")
  },
  {
    name: "a-media-articles",
    path: "/a/media-articles",
    meta: media_45articleswYC6zWHFrHuWINxaeNikcISzcKkQmAuXkOwHGzxvs8AMeta || {},
    component: () => import("/vercel/path0/pages/a/media-articles.vue")
  },
  {
    name: "a-modelReloader",
    path: "/a/modelReloader",
    component: () => import("/vercel/path0/pages/a/modelReloader.ts")
  },
  {
    name: "a-section-headers",
    path: "/a/section-headers",
    meta: section_45headersDUL_45lIC_45lJ8s_NI6qCiBeqm0T_45xzCk7j1GRdpAqXxc4Meta || {},
    component: () => import("/vercel/path0/pages/a/section-headers.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginyiubrZraL9EDD3J38Z_45ftLpCUym9OoYm1Hb9453rWnMMeta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  }
]